import IconsFreeShipping from '~icons/icons/FreeShipping.svg'
import IconsReturn from '~icons/icons/Returns.svg'
import IconsTailoring from '~icons/icons/Tailoring.svg'
import IconsPackaging from '~icons/icons/Packaging.svg'

export const iconsMap: Record<string, string> = {
  shipping: IconsFreeShipping,
  return: IconsReturn,
  tailoring: IconsTailoring,
  packaging: IconsPackaging,
}

<script setup lang="ts">
import { iconsMap } from './EditorialStripAnchorsItem.iconsMapping'
import type { EditorialStripAnchorsItemProps } from './EditorialStripAnchorsItem.props'

const props = defineProps<EditorialStripAnchorsItemProps>()

const emit = defineEmits<{
  'emit-item': [stripItem: EditorialStripAnchorsItemProps]
}>()

const showInCountry = computed(() => {
  return props.showInAllCountries !== false || props.showInCountry !== false
})
</script>

<template>
  <div
    v-if="showInCountry"
    class="flex items-center text-center lg:flex-col"
    :class="[
      { 'flex-col': type === 'alternative' },
      media ? 'gap-md' : 'gap-sm',
      textColor ? `text-editorial-${textColor}` : 'text-text-primary',
    ]"
  >
    <component
      :is="iconsMap[icon]"
      v-if="icon"
      :aria-hidden="true"
      class="h-6 w-6"
    />
    <UIMedia
      v-if="media && !icon"
      :media="media.media"
      aspect-ratio="1/1"
      class="h-36 w-36"
    />
    <UILink
      v-bind="link"
      :aria-haspopup="link.type === 'sidesheet' ? 'dialog' : null"
      :class="{ 'whitespace-nowrap lg:whitespace-normal': type === 'default' }"
      class="text-book-6 uppercase"
      @click="emit('emit-item', props)"
    />
  </div>
</template>
